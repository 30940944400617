* {
  box-sizing: border-box;
}

html,
body {
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  user-select: none;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
    sans-serif;
  position: fixed;
  overflow: hidden;
}

#root {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: url('cursor.png') 39 39, auto;
}

#root > div {
  position: absolute;
  width: 100vw;
  height: 100vh;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}

#root > div > div {
  background-color: white;
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45ch;
  height: 55ch;
  max-width: 300px;
  max-height: 570px;
  border-radius: 25px;
  border: 15px solid;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
}

.word {
  font-weight: 600;
  font-size: 6em;
  will-change: opacity;
}